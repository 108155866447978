import fontkit from "@pdf-lib/fontkit";
import { IConfigPdfCreation, IParticipantPdfCreation } from "features/certificateDownload/types/utils";
import { PDFDocument, PDFName, PDFString, rgb } from "pdf-lib";

import { formatDate } from "utils/dates.helpers";
import sanitizeFileName from "utils/sanitizeFileName";

import { getPdfTexts } from "../pdfGeneration/pdf.helpers";

import {
    base64ToArrayBuffer,
    createQRCode,
    generateId,
    getBadgeFormatSize,
    ISecondPageMeasurements,
} from "./Polotno.helpers";

const DATE_FORMAT = "DD Month YYYY";

const BASE_FONT_SIZE = 13.6;

/**
 * Calculates the font size based on the base font size.
 * @param {number} multiplier multiplier for the base font size. Defaults to 1.
 *
 * @returns {number} font size
 */
function calculateFontSize(multiplier = 1): number {
    return BASE_FONT_SIZE * multiplier;
}

/**
 * Generates a Polotno props object for the second page of the certificate.
 * @param {IParticipantPdfCreation} participant
 * @param {IConfigPdfCreation} config
 *
 * @returns {PolotnoDesigner.PolotnoPage} object with Polotno page props
 */
export const generateValidationPage = async (
    participant: IParticipantPdfCreation,
    config: IConfigPdfCreation
): Promise<PolotnoDesigner.PolotnoPage> => {
    const localizedPdfTexts = getPdfTexts(config.pdfLanguage);

    const qrcode = createQRCode(config.customCertificateValidationUrl);

    // Format
    const format = getBadgeFormatSize(config.badgeFormat);

    // Fonts and colors
    const titleColor = "rgba(126,126,126,1)";
    const contentColor = "rgba(63,63,63,1)";

    // Styled title
    const fontStyle = {
        fontSize: calculateFontSize(),
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        align: "left",
    };

    // Positioning
    const xFromLeft = 50; // Distance from the left side of the page (equal to the right side)
    const xFromRight = format.width - xFromLeft;
    const smallMargin = 25; // Space between title and content
    const bigMargin = 42; // Space between two blocks of text
    const borderMargin = 17; // Margin of grey background border
    const maxWidth = format.width - xFromLeft * 2; // Max width of the text block

    const baseWidth = 133; // Base width of the text block

    const qrCodeSize = 117; // Width and height of the QR code

    let yFromTop = 50; // Start position for the first block of text

    // Background element
    const greyBackground = {
        id: generateId(),
        type: "svg",
        x: 0,
        y: 0,
        width: format.width,
        height: format.height,
        src: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjRTFFMUUxIi8+Cjwvc3ZnPgo=",
    };

    // Background element
    const whiteBackground = {
        id: generateId(),
        type: "svg",
        x: borderMargin,
        y: borderMargin,
        width: format.width - borderMargin * 2,
        height: format.height - borderMargin * 2,
        src: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K",
    };

    // QR code element
    const qrCodeElement = {
        id: generateId(),
        type: "svg",
        src: qrcode,
        width: qrCodeSize,
        height: qrCodeSize,
        x: xFromRight - qrCodeSize,
        y: yFromTop,
    };

    const validateNowText = {
        id: generateId(),
        type: "text",
        text: localizedPdfTexts.ValidateNow,
        x: xFromRight - qrCodeSize,
        y: yFromTop + 134,
        width: qrCodeSize,
        fill: titleColor,
        ...fontStyle,
        align: "center",
        fontSize: calculateFontSize(7 / 8), // 8 was a base font size when the page was created
    };

    const titleText = {
        id: generateId(),
        type: "text",
        x: xFromLeft,
        y: yFromTop,
        width: 335,
        text: localizedPdfTexts.Header,
        fill: "rgba(50,50,50,1)",
        ...fontStyle,
        fontSize: calculateFontSize(2.25),
        align: "left",
    };

    // Cert info
    const issuedByTitle = {
        id: generateId(),
        type: "text",
        x: xFromLeft,
        y: (yFromTop += bigMargin * 2),
        width: maxWidth,
        text: localizedPdfTexts.IssuedBy,
        fill: titleColor,
        ...fontStyle,
    };

    const issuedByContent = {
        id: generateId(),
        type: "text",
        x: xFromLeft,
        y: (yFromTop += smallMargin),
        // We have to reduce the width, because on the right side we have a QR code (qrCodeSize) and default margin (xFromLeft)
        width: maxWidth - qrCodeSize - xFromLeft,
        text: config.eventCertificateIssuer,
        fill: contentColor,
        ...fontStyle,
    };

    const nameOfRecipientTitle = {
        id: generateId(),
        type: "text",
        x: xFromLeft,
        y: (yFromTop += bigMargin),
        width: maxWidth,
        text: localizedPdfTexts.NameOfRecipient,
        fill: titleColor,
        ...fontStyle,
    };

    const nameOfRecipientContent = {
        id: generateId(),
        type: "text",
        x: xFromLeft,
        y: (yFromTop += smallMargin),
        width: maxWidth,
        text: participant.recipient_name,
        fill: contentColor,
        ...fontStyle,
    };

    const certificateTitle = {
        id: generateId(),
        type: "text",
        x: xFromLeft,
        y: (yFromTop += bigMargin),
        width: maxWidth,
        text: localizedPdfTexts.Certificate,
        fill: titleColor,
        ...fontStyle,
    };

    const certificateContent = {
        id: generateId(),
        type: "text",
        x: xFromLeft,
        y: (yFromTop += smallMargin),
        width: maxWidth,
        text: config.eventName,
        fill: contentColor,
        ...fontStyle,
    };

    const issueDateTitle = {
        id: generateId(),
        type: "text",
        name: "issueDateTitle",
        x: xFromLeft,
        y: (yFromTop += bigMargin),
        width: baseWidth,
        text: localizedPdfTexts.IssueDate,
        fill: titleColor,
        ...fontStyle,
    };

    const issueDateContent = {
        id: generateId(),
        type: "text",
        name: "issueDateContent",
        x: xFromLeft,
        y: yFromTop + smallMargin,
        width: baseWidth,
        text: formatDate(participant.issue_date, DATE_FORMAT, config.pdfLanguage),
        fill: contentColor,
        ...fontStyle,
    };

    const expDate = [];
    if (participant.expiration_date) {
        expDate.push(
            {
                id: generateId(),
                type: "text",
                x: xFromLeft + 234,
                y: yFromTop,
                width: baseWidth,
                text: localizedPdfTexts.ExpirationDate,
                fill: titleColor,
                ...fontStyle,
            },
            {
                id: generateId(),
                type: "text",
                x: xFromLeft + 234,
                y: yFromTop + smallMargin,
                width: baseWidth,
                text: formatDate(participant.expiration_date, DATE_FORMAT, config.pdfLanguage),
                fill: contentColor,
                ...fontStyle,
            }
        );
    }

    yFromTop += smallMargin;

    // Content for this is set in the modifyPdf function
    const idTitle = {
        id: generateId(),
        type: "text",
        name: "idTitle",
        x: xFromLeft,
        y: (yFromTop += bigMargin),
        width: maxWidth,
        text: localizedPdfTexts.CertificateId,
        fill: titleColor,
        ...fontStyle,
    };

    // Content for this is set in the modifyPdf function
    const validationTitle = {
        id: generateId(),
        type: "text",
        name: "validationTitle",
        x: xFromLeft,
        y: (yFromTop += bigMargin + smallMargin),
        width: maxWidth,
        text: localizedPdfTexts.ValidationUrl,
        fill: titleColor,
        ...fontStyle,
    };

    yFromTop += smallMargin;

    const pageInit = [
        greyBackground,
        whiteBackground,
        ...(participant.validationPageEnabled ? [qrCodeElement, validateNowText] : []),
        titleText,
        issuedByTitle,
        issuedByContent,
        nameOfRecipientTitle,
        nameOfRecipientContent,
        certificateTitle,
        certificateContent,
        issueDateTitle,
        issueDateContent,
    ];

    if (expDate.length) {
        pageInit.push(...expDate);
    }

    const page = {
        id: generateId(),
        children: [...pageInit, ...(participant.validationPageEnabled ? [idTitle, validationTitle] : [])],
    };

    return page;
};

/**
 * Function to modify PDF page (used to add text and link) and download it
 * @param {string} base64Pdf - PDF file in base64 format
 * @param {Uint8Array} font - font file in Uint8Array format
 * @param {ReduxStoreParticipants.IStateParticipant} participant - participant data
 * @param {IConfigPdfCreation} config - config data
 * @param {ISecondPageMeasurements} textPositions - positions of text elements
 * @param {HTMLAnchorElement} link - href link for download
 */

export const generateAndDownloadPdf = async (
    base64Pdf: string,
    font: Uint8Array,
    participant: IParticipantPdfCreation,
    config: IConfigPdfCreation,
    link: HTMLAnchorElement,
    textPositions?: ISecondPageMeasurements
): Promise<void> => {
    const certificatePageLink = decodeURI(config.customCertificateValidationUrl);
    const localizedPdfTexts = getPdfTexts(config.pdfLanguage);

    const handleDownload = (PDFBytes: Uint8Array): void => {
        const blob = new Blob([PDFBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        // eslint-disable-next-line no-param-reassign
        link.href = url;
        // eslint-disable-next-line no-param-reassign
        link.download = `${
            (participant.recipient_name ? `${participant.recipient_name} - ` : "") + localizedPdfTexts.file_name
        }.pdf`;

        link.dispatchEvent(new MouseEvent("click"));
    };

    // Convert the base64 PDF to a Uint8Array
    const pdfBytes = new Uint8Array(base64ToArrayBuffer(base64Pdf));

    if (!textPositions) {
        handleDownload(pdfBytes);
        return;
    }

    // Load PDF
    const pdfDoc = await PDFDocument.load(pdfBytes);

    // Register fontkit with PDFDocument
    pdfDoc.registerFontkit(fontkit);

    // Load font
    const openSansFont = await pdfDoc.embedFont(font);

    // Get the last page of the PDF.
    const lastPage = pdfDoc.getPages()[pdfDoc.getPageCount() - 1];

    // Create the text field
    const textFontSize = textPositions.fontSize;
    const maxWidth = lastPage.getWidth();
    const maxHeight = lastPage.getHeight();
    const textColor = rgb(63 / 255, 63 / 255, 63 / 255);
    const textX = textPositions.x;

    const textY = maxHeight - textPositions.idContentY;
    const linkY = maxHeight - textPositions.validationContentY;

    if (participant.validationPageEnabled) {
        lastPage.drawText(participant.certificate_id, {
            x: textX,
            y: textY,
            size: textFontSize,
            font: openSansFont,
            color: textColor,
        });

        // Create the link field
        lastPage.drawText(certificatePageLink, {
            x: textX,
            y: linkY,
            size: textFontSize,
            font: openSansFont,
            color: textColor,
            maxWidth: maxWidth - 100,
            lineHeight: 15,
            wordBreaks: ["?"],
        });

        // Create the link annotation
        const pdfUrlDict = pdfDoc.context.obj({
            Type: "Annot",
            Subtype: "Link",
            Rect: [textX, linkY + 5, maxWidth - 50, linkY - 15], // It's a rectangle that defines the link area
            Border: [0, 0, 0],
            A: {
                Type: "Action",
                S: "URI",
                URI: PDFString.of(certificatePageLink),
            },
        });
        const pdfUrl = pdfDoc.context.register(pdfUrlDict);
        lastPage.node.set(PDFName.of("Annots"), pdfDoc.context.obj([pdfUrl]));
    }

    // Set Metadata
    pdfDoc.setCreator("Virtualbadge.io");
    pdfDoc.setProducer("Virtualbadge.io");
    pdfDoc.setTitle(config.eventName);
    pdfDoc.setAuthor(config.eventCertificateIssuer);

    const modifiedPdfBytes = await pdfDoc.save();

    handleDownload(modifiedPdfBytes);
};

/**
 * Function to modify PDF page (used to add text and link) and download it
 * @param {string} base64Pdf - PDF file in base64 format
 * @param {Uint8Array} font - font file in Uint8Array format
 * @param {ReduxStoreParticipants.IStateParticipant} participant - participant data
 * @param {IConfigPdfCreation} config - config data
 * @param {ISecondPageMeasurements} textPositions - positions of text elements
 */

export const generateAndReturnPdfBlob = async (
    base64Pdf: string,
    font: Uint8Array,
    participant: IParticipantPdfCreation,
    config: IConfigPdfCreation,
    textPositions?: ISecondPageMeasurements
): Promise<{ pdfBlob: Blob; fileName: string }> => {
    const fileName = sanitizeFileName(participant.certificate_id.substring(0, 4), {
        prefix: participant.recipient_name,
        fileType: "pdf",
    });
    const certificatePageLink = decodeURI(config.customCertificateValidationUrl);
    // Convert the base64 PDF to a Uint8Array.
    const pdfBytes = new Uint8Array(base64ToArrayBuffer(base64Pdf));

    // If there are no text positions, return the PDF blob without modifications
    if (!textPositions) {
        return {
            pdfBlob: new Blob([pdfBytes], { type: "application/pdf" }),
            fileName,
        };
    }

    // Load PDF
    const pdfDoc = await PDFDocument.load(pdfBytes);

    // Register fontkit with PDFDocument
    pdfDoc.registerFontkit(fontkit);

    // Load font
    const openSansFont = await pdfDoc.embedFont(font);

    // Get the last page of the PDF.
    const lastPage = pdfDoc.getPages()[pdfDoc.getPageCount() - 1];

    // Create the text field
    const textFontSize = textPositions.fontSize;
    const maxWidth = lastPage.getWidth();
    const maxHeight = lastPage.getHeight();
    const textColor = rgb(63 / 255, 63 / 255, 63 / 255);
    const textX = textPositions.x;

    const textY = maxHeight - textPositions.idContentY;
    const linkY = maxHeight - textPositions.validationContentY;

    lastPage.drawText(participant.certificate_id, {
        x: textX,
        y: textY,
        size: textFontSize,
        font: openSansFont,
        color: textColor,
    });

    // Create the link field
    lastPage.drawText(certificatePageLink, {
        x: textX,
        y: linkY,
        size: textFontSize,
        font: openSansFont,
        color: textColor,
        maxWidth: maxWidth - 100,
        lineHeight: 25,
        wordBreaks: ["?"],
    });

    // Create the link annotation
    const pdfUrlDict = pdfDoc.context.obj({
        Type: "Annot",
        Subtype: "Link",
        Rect: [textX, linkY + 8, maxWidth - 84, linkY - 25], // It's a rectangle that defines the link area
        Border: [0, 0, 0],
        A: {
            Type: "Action",
            S: "URI",
            URI: PDFString.of(certificatePageLink),
        },
    });
    const pdfUrl = pdfDoc.context.register(pdfUrlDict);
    lastPage.node.set(PDFName.of("Annots"), pdfDoc.context.obj([pdfUrl]));

    // Set Metadata
    pdfDoc.setCreator("Virtualbadge.io");
    pdfDoc.setProducer("Virtualbadge.io");
    pdfDoc.setTitle(config.eventName);
    pdfDoc.setAuthor(config.eventCertificateIssuer);

    const modifiedPdfBytes = await pdfDoc.save();

    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });

    return {
        pdfBlob: blob,
        fileName,
    };
};
