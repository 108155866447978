import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import badgeActionService from "../actions/badge";
import certificatesActionService from "../actions/certificates";
import validationActionService from "../actions/validation";

import type { TCertificatesStore } from "./types/certificates.types";

const initialState: TCertificatesStore = {
    data: [],
    staticChunkingAmount: 4,
    loadingState: "unfetched",
    reloadBadgeImage: true,
};

const certificateSlice = createSlice({
    name: "certificates",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(badgeActionService.bypassProfilePicture, (state, action) => ({
                ...state,
                data: state.data.map((cert) => ({
                    ...cert,
                    profile_picture_attached:
                        action.payload === cert.certificate_id ? true : cert.profile_picture_attached,
                })),
            }))
            .addCase(badgeActionService.attachParsedBadgePropsToCertificate, (state, action) => {
                const remappedData: Data[] = (state.data as Data[]).map((certificate) =>
                    certificate.certificate_id === action.payload.certificate_id ? action.payload : certificate
                );
                return {
                    ...state,
                    data: remappedData,
                };
            })
            .addCase(validationActionService.validateToken.fulfilled, (state, action) => ({
                ...state,
                data: action.payload.certificates,
                loadingState: "fulfilled",
                reloadBadgeImage: false,
            }))
            .addCase(validationActionService.validateToken.rejected, () => initialState)
            .addCase(badgeActionService.bakeBadgeImages.pending, (state) => ({ ...state, reloadBadgeImage: true }))
            .addCase(badgeActionService.bakeBadgeImages.fulfilled, (state, action) => ({
                ...state,
                reloadBadgeImage: false,
                data: state.data.map((certificate) => {
                    const reassignedState = action.payload.badge_images.find(
                        (badgeImage) => badgeImage.certificate_id === certificate.certificate_id
                    );
                    const reassignedImage = reassignedState?.badge_image_url || certificate.badge_image_url;
                    const reassignedCertificatePages =
                        reassignedState?.certificate_pages || certificate.certificate_pages;

                    const reassignedCertificate = {
                        ...certificate,
                        badge_image_url: reassignedImage,
                        certificate_pages: reassignedCertificatePages,
                    };
                    return reassignedCertificate;
                }),
            }))
            .addCase(badgeActionService.createBadgeImages.fulfilled, (state, action) => ({
                ...state,
                data: state.data.map((certificate) => {
                    const reassignedBase =
                        action.payload.find((baseImage) => baseImage.certificate_id === certificate.certificate_id)
                            ?.badge || certificate.badge_base_string;

                    const reassignedCertificate = { ...certificate, badge_base_string: reassignedBase };
                    return reassignedCertificate;
                }),
            }))
            .addCase(certificatesActionService.selectCertificate, (state) => ({
                ...state,
                reloadBadgeImage: false,
            }))
            .addCase(certificatesActionService.postStats.fulfilled, (state, action) => ({
                ...state,
                data: state.data.map((certificate) =>
                    certificate.certificate_id === action.payload
                        ? {
                              ...certificate,
                              badge_is_baked: true,
                          }
                        : certificate
                ),
            }))
            .addCase(certificatesActionService.setStaticChunkingAmount, (state, { payload }) => ({
                ...state,
                staticChunkingAmount: payload,
            }))
            .addMatcher(isAnyOf(validationActionService.validateToken.pending), (state) => ({
                ...state,
                loadingState: "pending",
            }));
    },
});

export default certificateSlice.reducer;
