/* eslint-disable no-param-reassign */
import { cloneDeep } from "lodash";

import i18n from "i18n";
import { formatDate } from "utils/dates.helpers";

import { createQRCode, createRegExpForText, getRecipientNameDimensionsFromCanvas } from "./Polotno.helpers";

/**
 * Get permanent dynamic fields from the dynamic fields
 * @param {PolotnoDesigner.DynamicFields} dynamicFields - array of dynamic fields
 * @returns {object} permanent dynamic fields
 */
export const getPermanentDynamicFields = (dynamicFields: PolotnoDesigner.DynamicFields) => {
    const recipientNameField = dynamicFields.find((element) => element.type === "recipient_name");
    const certificateIdField = dynamicFields.find((element) => element.type === "identification_number");
    const issueDateField = dynamicFields.find((element) => element.type === "issue_date");
    const expirationDateField = dynamicFields.find((element) => element.type === "expiration_date");

    return {
        recipientNameField,
        certificateIdField,
        issueDateField,
        expirationDateField,
    } as const;
};

/**
 *
 * @param {PolotnoDesigner.DynamicFields} dynamicFields - array of dynamic fields
 * @returns {PolotnoDesigner.DynamicFields} array of dynamic fields that contain only dynamic fields
 */
export const getDynamicTextFields = (dynamicFields: PolotnoDesigner.DynamicFields): PolotnoDesigner.DynamicFields =>
    dynamicFields.filter((element) => element.type === "dynamic_field");

/**
 * Set dynamic fields for Polotno page
 * @param {PolotnoDesigner.PolotnoBadgePropsData} polotnoBadgeProps - Polotno page props
 * @param {PolotnoDesigner.DynamicFields} dynamicFields - array of dynamic fields
 * @param {string} fullName - full name of the recipient
 * @param {Fieldmapping[]} fieldMapping - array of field mappings
 * @param {string} validationUrl - validation url
 * @param {string} certificateId - certificate id
 * @param {string} issueDate - issue date
 * @param {string} expirationDate - expiration date
 *
 * @returns {PolotnoDesigner.PolotnoBadgePropsData} polotnoBadgeProps with dynamic fields
 */
export const setDynamicFields = (
    polotnoBadgeProps: PolotnoDesigner.PolotnoBadgePropsData,
    dynamicFields: PolotnoDesigner.DynamicFields,
    fullName: string,
    fieldMapping: Fieldmapping[],
    validationUrl: string,
    certificateId: string,
    issueDate: string,
    expirationDate?: string
): PolotnoDesigner.PolotnoBadgePropsData => {
    // Make a deep copy so we don't work on the reference
    const polotnoProps = cloneDeep(polotnoBadgeProps);

    const currentLanguage = i18n.language === "de" ? "de" : "en";

    const polotnoElements = polotnoProps.pages.flatMap((page) => page.children);

    // Get permanent dynamic elements.
    const { recipientNameField, certificateIdField, issueDateField, expirationDateField } =
        getPermanentDynamicFields(dynamicFields);

    // Get qr-code elements.
    const qrCodeElements = polotnoElements.filter((item) => item.custom?.type === "qr_code");

    // Get all dynamic elements.
    const dynamicTextFields = getDynamicTextFields(dynamicFields);

    // Get values for dynamic elements from participant data except username.
    const dynamicFieldsFromParticipant = fieldMapping.filter((item) => item.id !== "username");

    // Replace all dynamic fields with values from participant data.
    dynamicTextFields.forEach((field) => {
        const dynamicFieldFromParticipant = dynamicFieldsFromParticipant.find((item) => item.name === field.value);
        if (dynamicFieldFromParticipant) {
            const searchingText = field.value;
            const regex = createRegExpForText(searchingText);

            polotnoElements.forEach((item: PolotnoDesigner.PolotnoObject) => {
                if (item.type === "text" && item.selectable !== false && item.text?.includes(`{{${searchingText}}}`)) {
                    item.text = item.text.replace(regex, dynamicFieldFromParticipant.value);
                }
            });
        }
    });

    /**
     * Replace recipient name keyword to real name in all static text elements
     * where it should be (set by user).
     *
     * @example `Recipient name: {{Recipient Name}}` -> `Recipient name: John Doe`
     */
    const setRecipientName = () => {
        const searchingName = recipientNameField?.value;
        if (!searchingName) return;

        const NameRegex = createRegExpForText(searchingName);

        polotnoElements.forEach((item: PolotnoDesigner.PolotnoObject) => {
            if (item.type === "text" && item.selectable !== false && item.text?.includes(`{{${searchingName}}}`)) {
                item.text = item.text.replace(NameRegex, fullName);
            }
            // Change elements dimensions if static text contains only recipient name
            if (item.text === fullName) {
                const { width, fontSize, x } = getRecipientNameDimensionsFromCanvas(
                    item as PolotnoDesigner.PolotnoObject,
                    polotnoProps.width as number
                );
                item.fontSize = fontSize;
                item.width = width;
                item.x = x;
            }
        });
    };

    /**
     * Replace id number keyword to real identification number in all static text elements
     * where it should be (set by user).
     *
     * @example `ID number: {{ID number}}` -> `ID number: 123456789`
     */
    const setIdNumber = (): void => {
        const searchingIdNumber = certificateIdField?.value;
        if (!searchingIdNumber) return;
        const idNumberRegex = createRegExpForText(searchingIdNumber);
        polotnoElements.forEach((item: PolotnoDesigner.PolotnoObject) => {
            if (item.type === "text" && item.selectable !== false && item.text?.includes(`{{${searchingIdNumber}}}`)) {
                item.text = item.text.replace(idNumberRegex, certificateId);
            }
        });
    };

    /**
     * Replace issue date and expiration date keywords to real dates in all static text elements
     * where it should be (set by user).
     *
     * Format of replacement date is set by user in the editor.
     *
     * @example `Issue date: {{Issue Date}}` -> `Issue date: 01.01.2021`
     */
    const setDates = (): void => {
        const searchingIssueDate = issueDateField?.value;
        const searchingExpirationDate = expirationDateField?.value;
        if (!searchingIssueDate || !searchingExpirationDate) return;
        const issueDateRegex = createRegExpForText(searchingIssueDate);
        const expirationDateRegex = createRegExpForText(searchingExpirationDate);

        polotnoElements.forEach((item: PolotnoDesigner.PolotnoObject) => {
            if (item.type === "text" && item.selectable !== false && item.text?.includes(`{{${searchingIssueDate}}}`)) {
                const dateFormatToReplace = issueDateField?.placeholder;
                const issueDateToReplace = formatDate(issueDate, dateFormatToReplace as string, currentLanguage);
                item.text = item.text.replace(issueDateRegex, issueDateToReplace);
            }
            if (
                item.type === "text" &&
                item.selectable !== false &&
                item.text?.includes(`{{${searchingExpirationDate}}}`) &&
                expirationDate
            ) {
                const dateFormatToReplace = expirationDateField?.placeholder;
                const expirationDateToReplace = formatDate(
                    expirationDate,
                    dateFormatToReplace as string,
                    currentLanguage
                );
                item.text = item.text.replace(expirationDateRegex, expirationDateToReplace);
            }
        });
    };

    setRecipientName();
    setIdNumber();
    setDates();

    if (qrCodeElements.length && validationUrl) {
        qrCodeElements.forEach((qrCodeElement) => {
            const { width, height } = qrCodeElement;
            qrCodeElement.src = createQRCode(validationUrl, width, height);
        });
    }

    return polotnoProps as PolotnoDesigner.PolotnoBadgePropsData;
};
