import { type FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import usePushSnack from "hooks/SnackbarManager";
import useTranslation from "i18n/hooks/useTranslation";
import {
    checkProfilePicture,
    disableProfilePicInPolotnoProps,
    findRecipientImageInBadgeProps,
    findRecipientImageInPolotnoProps,
    getTranslatedCertificateOrBadge,
} from "utils/misc";
import { theme } from "utils/Theme/theme";

import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import LaunchIcon from "@mui/icons-material/Launch";
import { Box, CircularProgress, InputAdornment, useMediaQuery } from "@mui/material";

import Button from "components/shared/Button";
import TextField from "components/shared/TextField/TextField";
import Typography from "components/shared/Typography";

import { useCertificateDownload } from "../../../../pages/CertificateDownload/CertificateDownload.hooks";
import badgeActionService from "../../../../redux/actions/badge";
import { useGetCurrentSelectedCertificate } from "../../../../redux/hooks/badge";
import useLoadingThunk from "../../../../redux/hooks/loading";
import controlSelectorService from "../../../../redux/selector/control";
import eventSelectorService from "../../../../redux/selector/events";
import { useAppSelector } from "../../../../redux/store";
import { EVariantTranslation } from "../../../../types/enums";
import backendPdfDownloadHelper from "../../../../utils/backendPdfDownloadHelper";

import * as styles from "./DownloadSection.styles";

const DownloadSection: FC = () => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const pushSnack = usePushSnack();
    const { handleSingleDownloadCertificate } = useCertificateDownload();
    const { getFlowLoading } = useLoadingThunk();

    const isDownloading = getFlowLoading("download");
    const currentDownloadFormat = useAppSelector(controlSelectorService.selectDownloadFormat);
    const isEventCertificate = useAppSelector(eventSelectorService.eventIsCertificate);

    const [bypassProfilePicture, setByPassProfilePicture] = useState(false);
    const [hasProfilePic, setHasProfilePic] = useState(false);

    // This is used to preserve order in render text helper (Use display css prop as alternative)
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const eventDetails = useAppSelector(eventSelectorService.selectFetchedEvent);
    const credentialIsCertificate = useAppSelector(eventSelectorService.eventIsCertificate);
    const certificate = useGetCurrentSelectedCertificate();

    useEffect(() => {
        if (certificate.badge_props_type === "badge") {
            const profilePic = findRecipientImageInBadgeProps(certificate.badge_props as VbDesigner.IBadgeProps);
            setHasProfilePic(!!profilePic);
        }
        if (certificate.badge_props_type === "polotno") {
            const profilePic = findRecipientImageInPolotnoProps(
                certificate.badge_props as PolotnoDesigner.PolotnoBadgePropsData
            );
            setHasProfilePic(!!profilePic);
        }
    }, [certificate]);

    const downloadWrapper = (downloadType: "pdf" | "png") => {
        // Find profile picture and check if it's the dummy
        if (!certificate.badge_props) throw new Error("Badge props not found");
        let showBypassProfilePictureAlert = false;
        if (certificate.badge_props_type === "badge") {
            const profilePic = findRecipientImageInBadgeProps(certificate.badge_props as VbDesigner.IBadgeProps);
            showBypassProfilePictureAlert = checkProfilePicture(profilePic, bypassProfilePicture);
        }
        if (certificate.badge_props_type === "polotno") {
            const profilePic = findRecipientImageInPolotnoProps(
                certificate.badge_props as PolotnoDesigner.PolotnoBadgePropsData
            );
            // TODO: Re-create this logic. Task: #13010.
            // showBypassProfilePictureAlert = checkPolotnoProfilePicture(profilePic, bypassProfilePicture);

            // Disable profile picture in Polotno props if no picture selected
            if (
                profilePic?.visible &&
                (profilePic?.type === "svg" || profilePic?.src?.includes("user_profile_dummy")) &&
                bypassProfilePicture
            ) {
                dispatch(
                    badgeActionService.attachParsedBadgePropsToCertificate({
                        ...certificate,
                        badge_props: disableProfilePicInPolotnoProps(
                            certificate.badge_props as PolotnoDesigner.PolotnoBadgePropsData
                        ),
                    })
                );
            }
        }

        if (showBypassProfilePictureAlert) {
            pushSnack({
                title: t("common.alerts.messages.by_pass_profile_picture.title"),
                body: t("common.alerts.messages.by_pass_profile_picture.body"),
                type: "warning",
                autoHideDuration: 100000,
            });
            setByPassProfilePicture(true);
            return;
        }

        handleSingleDownloadCertificate(downloadType, certificate);
    };

    const checkSingleRecipient = backendPdfDownloadHelper.checkSingleRecipient(
        eventDetails.event_pdf_type,
        certificate
    );

    const isBackendGeneratedWithoutUrlAndProfilePic =
        checkSingleRecipient.isBackendPdf && !checkSingleRecipient.recipientHasPdfUrl && !hasProfilePic;

    const enableOpenValidator =
        (!credentialIsCertificate && certificate.validation_url && certificate.badge_image_url) ||
        (credentialIsCertificate && certificate.badge_image_url);

    // We only show the png download button if the certificate is not encrypted
    // This is the case for all badges and for certificates that are not digitally signed.
    const showPngDownload = !eventDetails.event_pdf_encryption;

    return (
        <div>
            <Typography variant="h5" sx={{ mt: 1, mb: 1 }}>
                <>
                    {certificate.validation_page_enabled
                        ? t("certificate_download.download_section.heading", {
                              certificateType: getTranslatedCertificateOrBadge(
                                  eventDetails?.event_type,
                                  EVariantTranslation.LOWERCASE_SINGULAR
                              ),
                          } as any)
                        : t("certificate_download.download_section.download_heading", {
                              certificateType: getTranslatedCertificateOrBadge(
                                  eventDetails?.event_type,
                                  EVariantTranslation.LOWERCASE_SINGULAR
                              ),
                          } as any)}
                </>
            </Typography>
            <Box
                display="flex"
                gap={2}
                flexDirection={{
                    xs: "column",
                    sm: "row",
                }}
            >
                {certificate.validation_page_enabled && (
                    <Box width="100%" maxWidth={{ sm: 350 }}>
                        <TextField
                            placeholder={t("certificate_download.download_section.enter_certificate_liink", {
                                certificateType: getTranslatedCertificateOrBadge(
                                    eventDetails?.event_type,
                                    EVariantTranslation.LOWERCASE_SINGULAR
                                ),
                            } as any)}
                            value={certificate.validation_url}
                            fullWidth
                            sx={styles.LinkInput}
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <LaunchIcon
                                            {...(enableOpenValidator
                                                ? {
                                                      onClick: () => window.open(certificate.validation_url),
                                                      sx: { cursor: "pointer" },
                                                  }
                                                : {
                                                      sx: { cursor: "not-allowed" },
                                                  })}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {smallScreen && (
                            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }} gutterBottom={false}>
                                {t("certificate_download.download_section.description", {
                                    certificateType: getTranslatedCertificateOrBadge(
                                        eventDetails?.event_type,
                                        EVariantTranslation.LOWERCASE_SINGULAR
                                    ),
                                } as any)}
                            </Typography>
                        )}
                    </Box>
                )}
                <Box display="flex" flexDirection="row" gap={2}>
                    {isEventCertificate && (
                        <Button
                            variant="contained"
                            startIcon={
                                !certificate.validation_url || (isDownloading && currentDownloadFormat === "pdf") ? (
                                    <CircularProgress size={16} />
                                ) : (
                                    <DownloadForOfflineOutlinedIcon />
                                )
                            }
                            onClick={() => downloadWrapper("pdf")}
                            disabled={
                                !certificate.validation_url ||
                                isDownloading ||
                                isBackendGeneratedWithoutUrlAndProfilePic
                            }
                            sx={styles.downloadButton}
                        >
                            PDF
                        </Button>
                    )}
                    {showPngDownload && (
                        <Button
                            variant="contained"
                            startIcon={
                                !certificate.validation_url || (isDownloading && currentDownloadFormat === "png") ? (
                                    <CircularProgress size={16} />
                                ) : (
                                    <DownloadForOfflineOutlinedIcon />
                                )
                            }
                            disabled={!certificate.validation_url || isDownloading}
                            onClick={() => downloadWrapper("png")}
                            sx={styles.downloadButton}
                        >
                            PNG
                        </Button>
                    )}
                </Box>
            </Box>
            {!smallScreen && certificate.validation_page_enabled && (
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }} gutterBottom={false}>
                    {t("certificate_download.download_section.description", {
                        certificateType: getTranslatedCertificateOrBadge(
                            eventDetails?.event_type,
                            EVariantTranslation.LOWERCASE_SINGULAR
                        ),
                    } as any)}
                </Typography>
            )}
        </div>
    );
};

export default DownloadSection;
