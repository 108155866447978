import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { CertificateDownloaderApiRoutes } from "../../features/certificateDownload/api/routes";
import { axios } from "../../lib/axios";
import type { ThunkAPIConfig } from "../store";

import type { TPostStats, TSelectCertificateToDownload } from "./types/certificates.types";

const selectCertificate = createAction<number>("certificates/selectCertificate");

const selectCertificateToDownload = createAction<TSelectCertificateToDownload>(
    "certificates/TSelectCertificateToDownload"
);

const removeAllCertificateToDownload = createAction("certificates/removeAllCertificateToDownload");

const setDownloadFormat = createAction<undefined | "pdf" | "png">("certificates/setDownloadFormat");

const setStaticChunkingAmount = createAction<number>("certificates/setStaticChunkingAmount");

/** Send the post states to backend for statistics */
const postStats = createAsyncThunk<string, TPostStats, ThunkAPIConfig>(
    "certificates/TPostStats",
    async (arg, thunkAPI): Promise<string> => {
        const { certificate_id, stats_type } = arg;
        const { control } = thunkAPI.getState();
        const { slugs } = control;
        axios.post(
            CertificateDownloaderApiRoutes.postStatsDownload(
                slugs.orga_slug || "",
                slugs.certificate_slug || "",
                control.token,
                certificate_id,
                stats_type
            )
        );
        return certificate_id;
    }
);

const certificatesActionService = {
    selectCertificate,
    selectCertificateToDownload,
    postStats,
    setDownloadFormat,
    removeAllCertificateToDownload,
    setStaticChunkingAmount,
};

export default certificatesActionService;
